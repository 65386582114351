export default function() {
    if ($('.tabs').length > 0) {
        $('.tabs__mobile-title').on('click', function () {
            $('.tabs__mobile').css('display', 'flex');
        })
    
        $('.tabs__close').on('click', function () {
            $('.tabs__mobile').fadeOut();
        })
    }
}