export default function() {

    var getBeer = $('.beer__item')
    var getPaginator = $('.pagination')

    var currentFilters = {
        "style": [],
        "brewery": [],
        "abv": [],
        "ibu": [],
        "og":[]
    }
    var craftdepot = '/api-v2/beer_sorts_filter/';

    var timer = null;
    $('.beer__filters-item-checkbox').on('change', function (e) {
        clearTimeout(timer);
    //    console.log(e)
        var target = e.target;
        var name = target.name;
        var newid = target.id;
    //    console.log(newid)

    //    console.log(target)

        if(target.checked){
            target.classList.add("checked");
        }
        else{
            target.classList.remove("checked");
        }

    //    console.log(currentFilters[name].indexOf(newid));
        if (currentFilters[name].indexOf(newid) >= 0){ //|| currentFilters[name].indexOf(newid) == 1) {
            currentFilters[name].remove(newid);
        } else {
            currentFilters[name].push(newid);
        }
        var buffer=[];
        for(var filter in currentFilters) {
            if(currentFilters[filter].length > 0) {
                buffer.push(currentFilters[filter].join('G'))
            } else {
                buffer.push("none");
            }
        }
    //    console.log(buffer);

    var currentFiltersRow = buffer.join("/");

        var filter = function(){
            $.ajax({
            url: craftdepot + currentFiltersRow,
            success: function(data){
    //            console.log('successs')
                var a = new BeerItem();
                $(".beer__items").html(a.format(data.data.items));
    //            console.log(craftdepot + currentFiltersRow);
            }
        });

        }

        if(countElement('none',buffer) != 5){
            getPaginator.hide();
            timer = setTimeout(filter,500);
        }
        else{
            getPaginator.show();
            $(".beer__items").html(getBeer)
        }
    })



    $('.cancel_filters').on('click',function(){
        $('.checked').prop('checked',false).trigger('change');
        $('.beer__filters-count').text('нет выбранных фильтров');
    })
}

function BeerItem() {
	this.template = `<div class="beer__item beer_filter">
                    <a href="{{relative_url}}">
                        <div class="beer__item-type">{{sort}}</div>
                        <img src="{{brand_label}}" alt="Cherie Cherry" class="beer__item-image">
                        <div class="beer__item-title">{{title}}</div>
                        <div class="beer__item-subtitle">{{brewery_title}}</div>
                        <div class="beer__item-indicators">
                            <div class="beer__item-indicators-value">
                                <span class="beer__item-value-bold">{{gradus}}</span>
                                <span class="beer__item-value-normal">
                                    ABV
                                </span>
                            </div>
                            <div class="beer__item-indicators-value">
                                <span class="beer__item-value-bold">{{bitter_ibu}}</span>
                                <span class="beer__item-value-normal">
                                    IBU
                                </span>
                            </div>
                            <div class="beer__item-indicators-value">
                                <span class="beer__item-value-bold">{{bitter_OG}}</span>
                                <span class="beer__item-value-normal">
                                    OG
                                </span>
                            </div>
                        </div>
                    </a>
                </div>`;
}

BeerItem.prototype.format = function(data) {
	var result = "";
  for(var i = 0; i < data.length; i++) {
  	var buffer = this.template;
  	for(var d in data[i]) {
    	buffer = buffer.replace("{{" + d + "}}", data[i][d])
		}
    result += buffer;
  }
	return result;
}

function countElement(item,array) {
    var count = 0;
    $.each(array, function(i,v) { if (v === item) count++; });
    return count;
}

Array.prototype.remove = function(value) {
    var idx = this.indexOf(value);
    if (idx != -1) {
        // Второй параметр - число элементов, которые необходимо удалить
        return this.splice(idx, 1);
    }
    return false;
}
