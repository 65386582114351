export default function() {
    var minutes = 60 * parseInt($('#test-counter').text());
    var display = $('#test-counter');

    if(display.length) {
        startTimer(minutes, display);
    }
}

function startTimer(duration, display) {
    var timer = duration, minutes, seconds;
    var x = setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.text(minutes + "." + seconds);

        if (--timer < 0) {
            clearInterval(x);
            $.ajax({
                url: "/test-questions/",
                data: {
                    'timeout': 'timeout'
                },
                cache: false
            }).done(function (data, status, xhr) {
                $('.test').empty();
                $('.test').append(data);
            });

        }
    }, 1000);
}