export default function() {
    var expert;
    
    $('.expert-item').on('click', function () {
        var id = $(this).attr('id');
        expert = $("." + id);
        if(expert && expert.length) {
            expert.css('display', 'block');
        }
        $('.dark').fadeIn();
        $('body').css('overflow', 'hidden');
    });
    
    $('.expert-inner__close, .dark').on('click', function () {
        if(expert && expert.length) {
            expert.css('display', 'none');
        }
        $('.dark').fadeOut();
        $('body').css('overflow', 'auto');
    });
}