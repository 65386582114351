import testCountdown from './test-countdown';
import test from './test';

export default function() {
    if($('#email-form').length > 0) {
        $('#email-form').on('submit', function(event){
            event.preventDefault();
            var $form = $(this);

            $.ajax({
                url: $form.attr('action'),
                data: $form.serializeArray(),
                cache: false,
                method: $form.attr('method')
            }).done(function(data, status, xhr){
                $form.replaceWith(data);

                test();
                testCountdown();
            });
        });
    }
}
