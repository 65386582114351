export default function() {
    console.log('new');
    $('.header__search').on('click', function() {
        $('.page__container, .page__top-holder').addClass('blured');
        $('.header__search-container').fadeIn();
        $('body').css('overflow', 'hidden');
        window.location.hash = 'SEARCH_ALL'
    })

    $('.header__body-close').on('click', function() {
        $('.page__container, .page__top-holder').removeClass('blured');
        $('.header__search-container').fadeOut();
        $('body').css('overflow', 'auto');
        $('.header__body').find('.header__body-container').remove();
        window.location.hash = '';
    })

    var arr = ['#SEARCH_ALL','#SEARCH_BEER','#SEARCH_BREW','#SEARCH_TEXT'];

    $(window).bind('hashchange', function(){
        if($.inArray(window.location.hash, arr) > -1 ){
            var query = $('.header__body-input').val();
            page = 1;
            lastPage = false;
            searchAjax(query);
        }
    })
    let xhr = null;
    let timer = null;
    let page = 1;
    let lastPage = false;
    let oldQuery = null;

    $('.header__body-input').on('input', function() {
        var query = $('.header__body-input').val();

        page = 1;
        lastPage = false;

        if(timer || xhr){
            clearTimeout(timer);
            console.log('set timeout', query);
            timer = setTimeout(function(){
                console.log('timeout, searching', query, page);
                searchAjax(query);
                timer = null;
            }, 200);
        }else{
            console.log('first in sequence', query, page);
            searchAjax(query);
        }

    });

    function searchAjax(query){
        if(xhr){
            console.log('already running, aborting. searching', query);
            xhr.abort();
        }

        xhr = $.ajax({
            url: "/search/",
            data:{
                search: query,
                filter: window.location.hash,
                pagenum: page,
            },
            cache: false,
        });


        xhr.then(function(html){
            xhr = null;
            updateResults(html, page == 1);
            oldQuery = query;
        });

        return xhr;

    }


    function updateResults(html, clean){
        //$('.header__body').find('.header__body-container').remove();
        //$('.header__body').append(html);
        console.log('get results. clean:', clean);
        $('.header__body-tabs').css('display', 'flex');
        $('.header__body-items').fadeIn();

        if(clean){
            $('.header__body-items').html('');
        }

        var results = $(html);
        var count = results.find('.header__body-item').length;

        console.log('count:', count);
        if(count < 30){
            lastPage = true;
        }
        if(count > 0 || clean){
            $('.header__body-items').append(results);
        }
    }

    $('.header__search-container').on('scroll', function(){
        nextPageThreshold();
    })
    $(window).on('resize', function(){
        nextPageThreshold();
    })


    let nextPageElement = $('.header__body-next-page')[0];
    let windowHeight;
    let top;
    let nextPageInProgress = false;

    function nextPageThreshold(){
        updateMeasurements();
        var distance = getWindowBottomDistance();
        if(lastPage){
            return;
        }

        if(distance < 20 && !nextPageInProgress){
            console.log('next page', page);
            page ++;
            nextPageInProgress = true;

            var query = $('.header__body-input').val();
            searchAjax(query).always(function(){
                nextPageInProgress = false;
            }).then(function(){
                nextPageThreshold();
            });
        }
    }
    function updateMeasurements() {
        var rect = nextPageElement.getBoundingClientRect();
        top = rect.top + window.pageYOffset;
        windowHeight = window.innerHeight;

    }
    function getWindowBottomDistance() {
        var bottom = top + nextPageElement.clientHeight;
        var scrollY = window.pageYOffset + windowHeight;
        return bottom - scrollY;
    };
}
