export default function() {
    $('.inner__text-share-item .social-icons__item').click(function () {
        window.open($(this).attr('href'), "Поделиться", "width=600,height=340");
        return false;
    });
    
    $('.inner__share-item .social-icons__item').click(function () {
        window.open($(this).attr('href'), "Поделиться", "width=600,height=340");
        return false;
    });
    
    $('#mce-success-response').on('DOMSubtreeModified',function () {
        dataLayer.push({'event':'getEmail'});
    })
}
