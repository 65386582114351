export default function() {
    if($('#bar__map').length) {
        ymaps.ready(init);
    }
    
    function init() {
        // Создание карты.
    
        var myMap = new ymaps.Map("bar__map", {
            center: [55.76, 37.64],
            zoom: 7
        }, {
            searchControlProvider: 'yandex#search'
        });
    
        ymaps.geolocation.get({
            mapStateAutoApply: true
        }).then(function (result) {
            myMap.geoObjects.add(result.geoObjects);
        });
    
    
        $(geo_data).each(function (index, value) {
            var Placemark = null;
            if (value.brewery_logo) {
    
                Placemark = new ymaps.Placemark([value.geo_latitude, value.geo_longitude], {
                        balloonContentHeader:'<u><a href=' + value.url + '>' + value.title + '</a>'+'</u>',
                        balloonContentBody: value.address
                    },
                    {
                        iconLayout: 'default#image',
                        iconImageHref: value.brewery_logo,
                        iconImageSize: [42, 42],
                        iconImageOffset: [-5, -42]
                    }
                );
    
            }
    
            else {
                Placemark = new ymaps.Placemark([value.geo_latitude, value.geo_longitude], {
                    balloonContentHeader: value.title,
                    balloonContentBody: value.address
                })
            }
    
            myMap.geoObjects.add(Placemark);
        });
    }
}