export default function() {
  if ($('.beer-inner__slider').length > 0) {
    var beerInnerSwiperInit = new Swiper('.swiper-container', {
      speed: 400,
      slidesPerView: 5,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        767: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 20
        },
        1280: {
          slidesPerView: 4,
          spaceBetween: 30
        }
      }
    });
  }
}