export default function() {
    fixedHeader();
    
    $(document).on('scroll', function () {
        fixedHeader();
    });
    
    $('.header__burger').on('click', function () {
        $('.header-inner').addClass('show');
        $('.sub-inner').addClass('showsub');
        $('.dark').fadeIn();
        $('body').css('overflow', 'hidden');
    });
    
    $('.header-inner__close, .dark').on('click', function () {
        $('.header-inner').removeClass('show');
        $('.sub-inner').removeClass('showsub');
        $('.dark').fadeOut();
        $('body').css('overflow', 'auto');
    });

    function fixedHeader() {
        if ($(document).scrollTop() > 64) {
            $('.header').addClass('header-fixed');
            $('.subheader').addClass('header-hide');
        }
        else {
            $('.header').removeClass('header-fixed');
            $('.subheader').removeClass('header-hide');
        }
    }
}
