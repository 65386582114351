export default function() {
  $('.beer__filters-item-title').on('click', function () {
      $(this).next().slideToggle();
  })
  
  var countChecked = function() {
      var n = $(".beer__filters-item-checkbox:checked").length;
      if (n == 0) {
        $( ".beer__filters-count" ).text('нет выбранных фильтров');
      }
      
      if (n == 1) {
        $('.beer__filters-count').text('Выбран' + n + ' фильтр');
      }
      
      if (n > 1) {
        $('.beer__filters-count').text('Выбрано' + n + ' фильтра');
      }
      
      if (n > 4) {
        $('.beer__filters-count').text('Выбрано' + n + ' фильтров');
      }
    };
    countChecked();
     
    $(".beer__filters-item-checkbox").on( "click", countChecked );
  
  let $beerTitle = $('.beer__filters-title');
  let $beerFilters = $('.beer__filters');
  let $beerFiltersTitle = $('.beer__filters-item-title');
  let $beerFilterInner = $('.beer__filters-inner');
  let $beerFilterClose = $('.beer__filters-close');
  
  if ($(window).width() < 767) {
      $beerTitle.on('click', () => {
          $beerFilters.toggleClass('beer__filters-fixed');
          $beerFilterInner.toggleClass('display-block');
          $('.beer__filters-count').css('display', 'block');
      });
      $beerFiltersTitle.on('click', function() {
          $(this).toggleClass('arrow-up');
      })
  
      $beerFilterClose.on('click', function() {
          $beerFilters.removeClass('beer__filters-fixed');
          $beerFilterInner.removeClass('display-block');
          $('.beer__filters-count').css('display', 'none');
      })
  }
}
