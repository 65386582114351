export default function() {
    $('.inner__slider').each(function (index) {
        var swiperClass = 'swiperBox_' + index;
        var descriptionClass = 'swiper-desc_' + index;
        var next_slider = 'swiper-next_' + index;
        var prev_slider = 'swiper-prev_' + index;
        var counter = 'swiper-couner_' + index;
        $(this).children('.swiper-container').addClass(swiperClass);
        $(this).find('.swiper-button-next').addClass(next_slider);
        $(this).find('.swiper-button-prev').addClass(prev_slider);
        $(this).find('.inner__slider-container-description').addClass(descriptionClass);
        $(this).find('.inner__slider-count').addClass(counter);
        var innerSwiperInit = new Swiper('.' + swiperClass, {
            speed: 400,
            navigation: {
                nextEl: '.' + next_slider,
                prevEl: '.' + prev_slider
            }
            // effect: 'fade'
        });
    
        var associationDescription = new Swiper('.' + descriptionClass, {
            speed: 400,
            slidesPerView: 1
        });
    
        innerSwiperInit.controller.control = associationDescription;
        associationDescription.controller.control = innerSwiperInit;
    
        $('.' + counter).html(innerSwiperInit.activeIndex + 1 + ' из ' + innerSwiperInit.slides.length);
    
        innerSwiperInit.on('slideChange', function () {
            $('.' + counter).html(innerSwiperInit.activeIndex + 1 + ' из ' + innerSwiperInit.slides.length)
        });
    });
    
    
    $('#show-policy').on('click', function (e) {
        e.preventDefault();
        $('.policy-inner').css('display', 'block');
        $('.dark').fadeIn();
        $('body').css('overflow', 'hidden');
    });
    
    $('.policy-inner__close, .dark').on('click', function () {
        $('.policy-inner').css('display', 'none');
        $('.dark').fadeOut();
        $('body').css('overflow', 'auto');
    });
    
    $('#email-form').submit(function () {
        ButtonScrollTo('test-top');
    });

    $('[data-scroll-to]').on('click', function() {
        var name = $(this).attr('data-scroll-to');
        
        ButtonScrollTo(name);
    });

    function ButtonScrollTo(name, offset) {
        offset = typeof offset !== 'undefined' ? offset : 100;
        $("html,body").animate({
            scrollTop: $('#' + name).offset().top - offset
        }, 500);
    }
}
