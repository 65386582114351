export default function() {
    var btns = $('#buttons .button');

    btns.on('click', function() {
        btns.removeClass('active');
        $(this).addClass('active');
    });

    $('[data-toggle-visibility]').on('click', function() {
        var divId = $(this).attr('data-toggle-visibility');

        toggleVisibility(divId);
    });
}

function toggleVisibility(divId) {
    var current = document.getElementById(divId);
    current.style.display = 'block';
    hideNonVisibleDivs(current);
}

function hideNonVisibleDivs(current) {
    var switchable = $(".switchable");
    var i, div;
    for (i = 0; i < switchable.length; i++) {
        div = switchable[i];
        if (div === current) {
            div.style.display = "block";
        } else {
            div.style.display = "none";
        }
    }
}

