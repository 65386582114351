export default function() {
  if ($('.association-inner__slider, .association-inner__slider-description').length > 0) {
  
    var slides = $(".slider-content").length;
    var center;
  
    if(slides % 2 == 0){
        center = (slides / 2) - 1;
    }
    else{
        center = Math.floor(slides / 2)
    }
    var associationImage = new Swiper('.swiper-container-image', {
      speed: 400,
      slidesPerView: 5,
      centeredSlides: true,
      initialSlide: center,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        767: {
          slidesPerView: 1
        },
        1280: {
          slidesPerView: 3,
          spaceBetween: 30
        }
      }
    });
  
    var associationDescription = new Swiper('.swiper-container-description', {
      speed: 400,
      slidesPerView: 1,
      initialSlide: center,
    })
  
    associationImage.controller.control = associationDescription;
    associationDescription.controller.control = associationImage;
  }
}