export default function() {
    $('.question').each(function(e){
        if (e != 0)
        $(this).hide()
    });


    $('.question').on('submit', function(event){
       event.preventDefault();


       if($(this).is(':last-child')){

            var $forms = $('input:checked').serializeArray();

            $.ajax({
                url: "/test-questions/",
                data: {
                    'questions': $.param($forms),
                },
                cache: false,
            }).done(function(data,status,xhr){
                $('.test').empty();
                $('.test').append(data);
            });

            return
        };

       if ($(".test form:visible").next().length != 0)
            $(".test form:visible").next().show().prev().hide();

    });
}
