export default function() {
  if (!$.cookie('was')) {
    $('.dark').fadeIn();
    $('body').css('overflow', 'hidden');
    $('.modal__age').fadeIn();
  }
  
  $.cookie('was', true, {
    expires: 30,
    path: '/'
  }); 
  
  $('#modal__age-good').on('click', function() {
      $('.dark').fadeOut();
      $('body').css('overflow', 'auto');
      $('.modal__age').fadeOut();
  })

  $('#modal__age-bad').on('click', function() {
      window.close();
  })
}